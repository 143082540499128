import React from "react"
import { ThemeProvider } from "@material-ui/core/styles"
import lightTheme from "./src/templates/theme.js"
import ClientOnly from "./src/clientOnly.js"

export default function MuiRootWrapper({ element }) {
  return (
    <ThemeProvider theme={lightTheme}>
      <ClientOnly>{element}</ClientOnly>
    </ThemeProvider>
  )
}
