import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#fff",
            accent: "#00FF85", // Акцентный цвет
        },
        background: {
            main: "#0D0D0D", // Цвет основного фона
            bigCard: "#000", // Цвет фона большой карточки
            card: "#1D1D1D", // Цвет фона карточки
        },
        width: {
            limit: '1440px',
        },
    },
    typography: {
        fontFamily: [
            'Space Grotesk',
            'Krona One'
        ].join(','),
        h1: {
            // Название товара
            color: "#fff",
            fontSize: 20,
            fontFamily: 'Krona One',
            '@media (max-width: 1024px)': {
            },
            '@media (max-width: 767px)': {
            },
        },
        h2: {
            // Основные большие заголовки
            color: "#fff",
            fontSize: 72,
            fontFamily: 'Krona One',
            lineHeight: '150%',
            '@media (max-width: 1024px)': {
                fontSize: '6vw',
            },
            '@media (max-width: 767px)': {
                fontSize: '8vw',
            },
        },
        h3: {
            // Заголовки структурных пунктов и их номеров
            color: "#fff",
            fontSize: 20,
            fontFamily: 'Krona One',
            '@media (max-width: 1024px)': {
                fontSize: '1.6vw',
            },
            '@media (max-width: 767px)': {
                fontSize: '3vw',
            },
        },
        h4: {
            // Пункты с номерами
            color: "#fff",
            fontSize: 18,
            fontFamily: 'Krona One',
            '@media (max-width: 1024px)': {
            },
            '@media (max-width: 767px)': {
            },
        },
        h5: {
            // Пункты без номеров в обводке
            color: "#fff",
            fontSize: 14,
            fontFamily: 'Krona One',
            '@media (max-width: 1024px)': {
            },
            '@media (max-width: 767px)': {
            },
        },
        h6: {
            // Заголовки пунктов на карточке товара
            color: "#fff",
            fontSize: 20,
            fontFamily: 'Krona One',
            '@media (max-width: 1024px)': {
            },
            '@media (max-width: 767px)': {
            },
        },
        body1: {
            // Обычный текст
            color: "#fff",
            fontSize: 17,
            fontFamily: 'Space Grotesk',
            lineHeight: '150%',
            '@media (max-width: 1024px)': {
                fontSize: "2vw",
                lineHeight: '180%',
            },
            '@media (max-width: 767px)': {
                fontSize: "4vw",
            }
        },
        body2: {
            // Серый обычный текст
            color: "#999999",
            fontSize: 14,
            fontFamily: 'Space Grotesk',
            lineHeight: '150%',
            '@media (max-width: 1024px)': {
                fontSize: "1.2vw",
                lineHeight: '180%',
            },
            '@media (max-width: 767px)': {
                fontSize: "3.5vw",
            },
        },
        subtitle1: {
            // Текст в кнопках
            color: "#000",
            fontSize: 14,
            fontFamily: 'Krona One',
            textTransform: 'none',
            lineHeight: '1 !important',
            '@media (max-width: 1024px)': {
                fontSize: '1.4vw',
            },
            '@media (max-width: 767px)': {
                fontSize: '2.8vw',
            },
        },
        subtitle2: {
            // Текст ссылок в меню в шапке
            color: "#fff",
            fontSize: 14,
            fontFamily: 'Krona One',
        },
    },
    overrides: {
        // Название класса компонента
        MuiButton: {
            // Название css-правила, смотреть в mui
            text: {
            },
        },
    },
    props: {
        // Название класса компонента
        MuiButton: {
            // Props, который нужно изменить
            disableRipple: true, // Отменяет эффект волны для всех кнопок
        },
    },
});

export default theme;
