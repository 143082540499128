import React from "react"

import Logo from "./static/svg/logo.svg"

export default function ClientOnly({ children, ...delegated }) {
  const [hasMounted, setHasMounted] = React.useState(false)
  React.useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted)
    return (
      <>
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100vh",
            width: "100vw",
            zIndex: 999999,
            background: "#0D0D0D",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            flexWrap: "nowrap",
          }}
        >
          <Logo />
        </div>

        <div {...delegated}>{children}</div>
      </>
    )

  return <div {...delegated}>{children}</div>
}
